// Here you can add other styles
.login-box {
    border-radius: 10px;
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}
.login-box .card{
    border: unset;
}
.card {
    border: unset;
    box-shadow: 0 3px 6px rgba(0,0,0,0.25), 0 2px 2px rgba(0,0,0,0.22);
    border-radius: 10px;
}